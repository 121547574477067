import { notification } from "components/Notification";
import { Resources } from "resources/Resources";
import { PortalService } from "services/PortalService";

if (process.env.NODE_ENV && process.env.NODE_ENV.trim() === "local") {
  require("dotenv").config();
}
export class ServiceConfig {
  public static gateway_base_url: string | undefined =
    localStorage.getItem("gatewayUrl") || undefined;

  public static theme_color: string = localStorage.getItem("colorTheme") || "#00aeef";

  public static async initializeGatewayUrl() {
    if (this.gateway_base_url) return;
    try {
      const gatewayUrl = await PortalService.getGatewayUrl();
      this.gateway_base_url = gatewayUrl;
      localStorage.setItem("gatewayUrl", gatewayUrl);
    } catch (error) {
      notification.error(
        {
          title: Resources.get("CommandCenter", "gatewayUrlNotFound", "label"),
        },
        {
          toastId: "Gateway Url Error",
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
        }
      );
    }
  }

  public static async initializeColorTheme() {
    if (!localStorage.getItem("colorTheme")) {
      try {
        const colorThemeValue = await PortalService.getColorTheme();
        this.theme_color = colorThemeValue;
        localStorage.setItem("colorTheme", colorThemeValue);
      } catch (error) {
        return
      }
    } 
  }

  public static async initializePortalConfiguration() {
    await this.initializeGatewayUrl()
    await this.initializeColorTheme()
  }

  public static readonly language_base_url: string | undefined =
    process.env.REACT_APP_LANGUAGE_TOOLKIT;
  public static readonly spring_logger: string | undefined =
    process.env.REACT_APP_SPRING_LOGGER_SENTRY_DSN;
  public static readonly command_center_url: string | undefined =
    process.env.REACT_APP_COMMAND_CENTER_URL;
  public static readonly workspace_embedded_url: boolean | undefined =
    process.env.REACT_APP_EMBEDDED_WORKSPACE_URL === "true";
  public static readonly websocket_base_url: string | undefined =
    process.env.REACT_APP_WEBSOCKET_BASE_URL;
}
