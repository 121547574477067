import axios from "axios";
import { Endpoints } from "config/endpoints";
import { IUserConfigurationParameters } from "models/UserConfigurationParameters";

interface IConfigurationParameterService {
  getServiceEndpoint(): string;
  getUserConfigurationParameters(): Promise<IUserConfigurationParameters>;
}

export const ConfigurationParameterService: IConfigurationParameterService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_CONFIGURATION_PARAMETER_ENDPOINT");
  },

  getUserConfigurationParameters: async (): Promise<IUserConfigurationParameters> => {
    const ConfigurationParameterServiceUrl = ConfigurationParameterService.getServiceEndpoint();
    const { data } = await axios.get(
      `${ConfigurationParameterServiceUrl}/configurationParameters`
    );
    return data;
  },
};
