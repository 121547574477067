import React, { createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { notification } from "components/Notification";
import { Resources } from "resources/Resources";
import { IUserConfigurationParameters } from "models/UserConfigurationParameters";
import { useAppState } from "./StateProvider";
import { ConfigurationParameterService } from "services/ConfigurationParameterService";

interface ConfigurationParametersContextProps {
  configurationParameters: IUserConfigurationParameters;
}

const ConfigurationParametersContext = createContext<ConfigurationParametersContextProps | undefined>(undefined);

export const ConfigurationParametersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { identity } = useAppState();

  const { data: configurationParameters = {} } = useQuery(
    ["configurationParameters"],
    ConfigurationParameterService.getUserConfigurationParameters,
    {
      enabled: !!identity?.isAuthenticated,
      onError: (e) => {
        notification.warn({
          title: Resources.get(
            "CommandCenter",
            "configurationParametersRequestError",
            "label"
          ),
        });
      },
    }
  );

  return (
    <ConfigurationParametersContext.Provider value={{ configurationParameters }}>
      {children}
    </ConfigurationParametersContext.Provider>
  );
};

export const useConfigurationParameters = () => {
  const context = useContext(ConfigurationParametersContext);
  if (!context) {
    throw new Error(
      "useConfigurationParameters must be used within a ConfigurationParametersProvider"
    );
  }
  return context.configurationParameters;
};
