import axios from "axios";

interface IPortalService {
  getGatewayUrl(): Promise<string>;
  getColorTheme(): Promise<string>;
}

export const PortalService: IPortalService = {
  getGatewayUrl: async (): Promise<string> => {
    const { data } = await axios.get(`/api/gatewayUrl`);
    return data.gatewayUrl;
  },

  getColorTheme: async (): Promise<string> => {
    const { data } = await axios.get(`/api/colorTheme`);
    return data.colorTheme;
  },
};
